import React from "react";
import axios from "axios";
import {Upload,message,Col,Row,notification} from "antd";
import {CaretRightOutlined, CaretLeftOutlined,ToTopOutlined,CheckOutlined,SendOutlined} from "@ant-design/icons";
import { NotificationPlacement } from 'antd/es/notification';
import {useState, useEffect, useRef} from "react";
import { endpoint } from "../utils/endpoint";
import {Card, CardContent, Typography, List, Radio, RadioGroup, FormControl, Checkbox, Toolbar,Avatar, 
  Box, Stack, Grid, ButtonGroup, LinearProgress, FormControlLabel, CardHeader, CardActionArea, CardActions} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Button from "@material-ui/core/Button";
import { trackPromise } from "react-promise-tracker";
import { LoaderIndicators } from "../utils/loader";
import { Markup } from 'interweave';
import { makeStyles } from "@material-ui/core/styles";
import Swal from 'sweetalert2';
import {sleep} from '../utils/sleep';
import resfreshPage from "../utils/confirmRefresh";
import { useHistory } from "react-router-dom";
import { Timer } from "../utils/timerPass";
import AppBar from '@mui/material/AppBar';
import logo from '../assets/images/naker.png';
import PageNotFound from "../components/layout/Error";
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import $ from 'jquery';
import Result from "./Result";

export default function Quiz() {
    const getToken = localStorage.getItem("data_peserta");
    const parseToken = JSON.parse(getToken);
    const textColor = "gray.700";
    const [data_quiz, setDataQuiz] = useState([]);
    const [persists, setPersists] = useState([]);
    const [qnIndex, setQnIndex] = useState(1);
    const [bobot, setBobot] = useState({});
    const [bobotCheck, setBobotCheck] = useState([]);
    const [jawab, setJawab] = useState({});
    const [checkJawab, setCheckJawab] = useState([]);
    const [timeTaken, setTimeTaken] = useState(0);
    const [loading, setLoading] = useState(false);
    const [backLoading, setBackLoading] = useState(false);
    const [loadingResult, setLoadingResult] = useState(false);
    const [isFinish, setIsFinish] = useState(false);
    const [mainSelections, setMainSelections] = useState({});
    const [subSelections, setSubSelections] = useState({}); // Track sub option selections
    const ref = useRef(null);
    const redirect = useHistory();
    const [token, setToken] = useState(parseToken?.token.access_token || null);

    const handleMainChange = (quizId, subIdx, subbabIdx, optionIdx, isSingleChoice) => {
      setMainSelections((prevState) => {
        const updatedQuiz = prevState[quizId] || {};
        const updatedSubSoal = updatedQuiz[subIdx] || {};
        const updatedSubBabSoal = updatedSubSoal[subbabIdx] || {};
  
        if (isSingleChoice) {
          // Single choice: only one can be selected, reset others
          return {
            ...prevState,
            [quizId]: {
              ...updatedQuiz,
              [subIdx]: {
                ...updatedSubSoal,
                [subbabIdx]: { [optionIdx]: true }, // Only allow one option to be selected
              },
            },
          };
        } else {
          // Multiple choice: toggle selection
          return {
            ...prevState,
            [quizId]: {
              ...updatedQuiz,
              [subIdx]: {
                ...updatedSubSoal,
                [subbabIdx]: {
                  ...updatedSubBabSoal,
                  [optionIdx]: !updatedSubBabSoal[optionIdx] || false, // Toggle selection
                },
              },
            },
          };
        }
      });
    };
  
    // Function to handle sub-options state
    const handleSubChange = (quizId, subIdx, subbabIdx, optionIdx, subOptionIdx, isSubEnabled, isSingleChoice) => {
      if (!isSubEnabled) return; // Disable interaction if the main option is not selected
  
      setSubSelections((prevState) => {
        const updatedQuiz = prevState[quizId] || {};
        const updatedSubSoal = updatedQuiz[subIdx] || {};
        const updatedSubBabSoal = updatedSubSoal[subbabIdx] || {};
        const updatedSubOptionGroup = updatedSubBabSoal[optionIdx] || {};
  
        if (isSingleChoice) {
          // Single choice: only one sub-option can be selected within the group
          return {
            ...prevState,
            [quizId]: {
              ...updatedQuiz,
              [subIdx]: {
                ...updatedSubSoal,
                [subbabIdx]: {
                  ...updatedSubBabSoal,
                  [optionIdx]: { [subOptionIdx]: true }, // Only one sub-option can be selected
                },
              },
            },
          };
        } else {
          // Multiple choice: toggle sub-option selection
          return {
            ...prevState,
            [quizId]: {
              ...updatedQuiz,
              [subIdx]: {
                ...updatedSubSoal,
                [subbabIdx]: {
                  ...updatedSubBabSoal,
                  [optionIdx]: {
                    ...updatedSubOptionGroup,
                    [subOptionIdx]: !updatedSubOptionGroup[subOptionIdx] || false,
                  },
                },
              },
            },
          };
        }
      });
    };

    let timer;

    const startTimer = () => {
      timer = setInterval(() => {
        setTimeTaken(prev => prev+1);
      }, [1000]);
    };

    const useStyles = makeStyles((theme) => ({
      root: {
        margin: theme.spacing(1)
      },
      typo: {
        flexGrow: 1,
        textAlign: "center"
      }
    }));

    const classes = useStyles();

    const formProps = {
      name: "file",
      action: `${endpoint}/kuisioner/upload_file`,
      headers: {
        authorization: "authorization-text",
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          message.success(`Sukses mengupload file`);
        } else if (info.file.status === "error") {
          message.error(`Gagal mengupload file.`);
        }
      },
    };
    
    const get_quiz = async () => {
        await axios.get(`${endpoint}/kuisioner/all_data_quiz/1`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }).then(res => {
            setDataQuiz(res.data);
            console.log(res.data);
          })
          .catch(err => {
            console.log(err);
          });
    };

    const backSoal = async () => {
      let count = qnIndex-1;
      let id = count;
      setBackLoading(true);
      await axios.get(`${endpoint}/kuisioner/all_data_quiz/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
      }).then(res => {   
          setBackLoading(false);
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          setDataQuiz(res.data);
          setQnIndex(id);
          setBobotCheck((cur) => 
            cur.filter((obj) => obj.check !== id)
          );
          setCheckJawab((del) => 
              del.filter((obj) => obj.check !== id)
          );
      })
      .catch(err => {
        message.error(`${err.response.status}, Page tidak ditemukan`);
        setBackLoading(false);
      });
    };

    const nextSoal = async () => {
      let count = qnIndex+1;
      let id = count;
      setLoading(true);
      await axios.get(`${endpoint}/kuisioner/all_data_quiz/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      .then(res => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          setLoading(false);
          setDataQuiz(res.data);
          setQnIndex(id);
          document.getElementById(`${id-1}`).style.background = '#4CAF50';
      })
      .catch(err => {
        message.error(`${err}, Page tidak ditemukan`);
        setLoading(false);
      });
    };

    useEffect(() => {
        trackPromise(
          get_quiz()
        );

    }, []);

    const values = Object.values(bobot);
    const values2 = bobotCheck.reduce((acc, item) => acc + item.bobot, 0);
    const sum = values.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

    console.log({"nilai_peserta":sum + values2});
    console.log(bobotCheck);
    console.log(values2);
    const total = values2 + sum;
    
    let cari_id = data_quiz.map(dt => dt.id);
    let hps = bobotCheck.map((obj) => obj.bobot);
    console.log(hps);

    const submitResult = async (e) => {
        e.preventDefault();
        Swal.fire({
          title: 'Konfirmasi!',
          html: '<b> Apakah jawaban yang diisi sudah lengkap?</b>',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Ya, Sudah Lengkap",
          cancelButtonText: "Belum, Periksa jawaban",
          confirmButtonColor: '#15406A',
          cancelButtonColor: '#d33',
          icon: 'warning'
        }).then(async (result) => {
          if(qnIndex === 10) {
            document.getElementById(`${qnIndex}`).style.background = '#4CAF50';
          } else {
            return null;
          }
          let allJawaban = [{
              "pilih_salah_satu": jawab,
              "pilih_banyak": checkJawab
          }];
          if(result.isConfirmed) {
              setLoadingResult(true);
              await axios.post(`${endpoint}/kuisioner/submit_quiz`, {
                "tipe": parseToken?.kategori || null,
                "score": total,
                "total_score": 0,
                "jawaban": allJawaban,
                "id_partisipan": parseToken?.id_karyawan || null,
                "id_company": parseToken?.id_perusahaan || null,
                "perusahaan": parseToken?.nama_perusahaan || null
            }).then(response => {
                setLoadingResult(false);
                message.success(`Data berhasil di simpan`);
                setToken(null);
                setIsFinish(true);
                window.onbeforeunload = function() { return "Your work will be lost."; };
                console.log(response.data);
            }).catch(error => {
              message.error(`Data pending, ${error.response.status}`);
              setLoading(false);
            });
          } else {
            return false;
          }
        });
    };

    const targetClick = (e, id, val, jawaban, indx) => {
        if(e.target.checked) {
          e.persist();
          setBobotCheck((prev) =>
            [...prev, {id: id, bobot: val, check: indx}]
          );
          setCheckJawab((chk) => 
            [...chk, {id: id, jawaban: jawaban, check: indx}]
          );
        } else {
          setBobotCheck((cur) => 
            cur.filter((obj) => obj.id !== id)
          );
          setCheckJawab((del) => 
              del.filter((obj) => obj.id !== id)
          );
          console.log(bobotCheck);
        }
    };

    function selectOnlyThis(id){
      var myCheckbox = document.getElementsByName("myCheckbox");
      Array.prototype.forEach.call(myCheckbox, function(el){
        el.checked = false;
      });
    }

    console.log(checkJawab);
    console.log(data_quiz);
    const ar = [1,2,3,4,5,6,7,8,9,10];
    resfreshPage();
    
    return (
      <div className="container">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" sx={{ bgcolor: "#15406A", 'color': 'white' }}>
            <Toolbar>
            <Avatar alt="Remy Sharp" src={logo} sx={{ mr: 2 }} />
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                 Hubungan Industrial
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <br />
        {token === null && isFinish === false ? (
            <PageNotFound />
        ) : [
          token === null && isFinish === true
          ?
            <Result />
          :
        (
          <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={18} className="mb-24">
            <Card sx={{ boxShadow: 3, maxWidth: '1100px', margin: 2 }}>
              <Box>
              <LinearProgress variant="determinate" value={(qnIndex)*100/10} />
              </Box>
                <CardContent>
                  <List>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ Width: '1100px' }}
                  >
                    <LoaderIndicators />
                  </Box>
                  {data_quiz.map((quiz, idx) => {
                    return (
                      quiz.length !== 0
                      ? 
                      <CardContent>
                        <Typography
                          fontSize='xl'
                          color={textColor}
                          fontWeight='bold'
                          textAlign='left'
                          variant="h5"
                          mb='13px' key={idx}>
                          {quiz.id}.{quiz.soal} 
                        </Typography>
                          {quiz.sub_soal.map((sub, index) => {
                            return(
                              <div>
                                {sub.sub_soal !== ""
                                  ?
                                  <Typography
                                    fontSize='sm'
                                    color={textColor}
                                    fontWeight='bold'
                                    textAlign='left'
                                    variant="h6"
                                    mb='3px'
                                    mt='3px' 
                                    key={idx}>
                                    {quiz.id}.{index+1}.{sub.sub_soal}
                                   </Typography>
                                  : ""
                                }
                                {sub.subbab_soal.map((subbab, subbabIdx) => (
                                    subbab.soal !== "" && subbab.soal !== "string"
                                      ?
                                      <React.Fragment>
                                      <FormControl component="fieldset" >
                                      <b>{quiz.id}.{index+1}.{subbabIdx+1}.{subbab.soal}</b>
                                          <RadioGroup className={classes.root} >
                                          {subbab.pilih.map((pilih, idx) => {
                                            const isSingleChoice = subbab.tipe === "Pilih Satu";
                                            const isSelected =
                                            mainSelections[quiz.id]?.[index]?.[subbabIdx]?.[idx] || false;
                                            return(
                                              pilih !== "" && pilih !== "string"
                                              ?
                                              <List>
                                              {subbab.tipe === "Pilih Banyak"
                                                ?
                                                <React.Fragment>
                                                  <FormControlLabel 
                                                  control={<Checkbox checked={isSelected} 
                                                  required={true} 
                                                  key={`${quiz.id}.${index}.${idx}`}
                                                  value={pilih}
                                                  defaultChecked={pilih.check}
                                                  onChange={(e) => {
                                                    handleMainChange(quiz.id, index, subbabIdx, idx, false)
                                                    const val = subbab.bobot[idx]
                                                    let cont = quiz.id + "." 
                                                    let id = `${cont}${index}.${idx}`
                                                    let jawaban = e.target.value;
                                                    let indx = quiz.id;
                                                    targetClick(e, id, val, jawaban, indx); 
                                                  }}
                                                  />}
                                                  label={<Markup content={String.fromCharCode(65+idx)+ ". " + pilih} />} />
                                                  {subbab.sub_pilih?.map((subjwb, iddx) => { 
                                                    return(
                                                      subjwb !== null && subjwb.id === idx
                                                      ? 
                                                      <div className={classes.root}>
                                                        {subjwb.pilih.map((itm, i) => {
                                                          const isSubSingleChoice = subjwb.tipe === "Pilih Satu";
                                                          const isSubSelected =
                                                          subSelections[quiz.id]?.[index]?.[subbabIdx]?.[idx]?.[i] || false;
                                                          return(
                                                            subjwb.tipe === "Pilih Banyak"
                                                              ?
                                                              <FormControlLabel 
                                                              control={<Checkbox
                                                                checked={isSubSelected}
                                                                disabled={!isSelected}
                                                                onChange={(e) => {
                                                                  handleSubChange(
                                                                    quiz.id,
                                                                    index,
                                                                    subbabIdx,
                                                                    idx,
                                                                    i,
                                                                    isSelected,
                                                                    false
                                                                  )
                                                                  const val = subjwb.bobot[i]
                                                                  let cont = quiz.id + "." 
                                                                  let id = `${cont}${index}.${idx}.${i}`
                                                                  let jawaban = e.target.value;
                                                                  let indx = quiz.id;
                                                                  targetClick(e, id, val, jawaban, indx);
                                                                }} 
                                                                required={true} />
                                                              }
                                                              value={itm}
                                                              key={itm}
                                                              label={<Markup content={"" + itm} />}/>
                                                              : 
                                                              <FormControlLabel 
                                                              value={itm}
                                                              key={`${itm}`} 
                                                              label={<Markup content={"" + itm} />}
                                                              control={<Radio 
                                                                required={true}
                                                                checked={isSubSelected}
                                                                  disabled={!isSelected}
                                                                  onChange={(e) => setBobot((prev) => {
                                                                    let cont = quiz.id + "." 
                                                                    let cpt = String.fromCharCode(65+idx)+ ".";
                                                                    setJawab((jwb) => {
                                                                      return {...jwb, [`jawaban${cont}${index+1}`]: cpt + e.currentTarget.value}
                                                                    })
                                                                    handleSubChange(
                                                                      quiz.id,
                                                                      index,
                                                                      subbabIdx,
                                                                      idx,
                                                                      i,
                                                                      isSelected,
                                                                      true
                                                                    )                             
                                                                    return {...prev, [`${cont}${index}`]: subjwb.bobot[i]}
                                                                    })
                                                                  }
                                                                />
                                                              } 
                                                              />
                                                          )
                                                        })}
                                                      </div>
                                                      : ""
                                                    )
                                                  })}
                                                </React.Fragment>
                                                : <FormControlLabel 
                                                    value={pilih}
                                                    key={`${pilih}${quiz.id}${idx}`} 
                                                    label={<Markup content={String.fromCharCode( 65+idx)+ ". " + pilih} />}
                                                    control={<Radio 
                                                      required={true}
                                                      checked={isSelected}
                                                      onChange={(e) => setBobot((prev) => {
                                                        let cont = quiz.id + "." 
                                                        let cpt = String.fromCharCode(65+idx)+ ".";
                                                        setJawab((jwb) => {
                                                          return {...jwb, [`jawaban${cont}${index+1}`]: cpt + e.currentTarget.value}
                                                        })
                                                        handleMainChange(quiz.id, index, subbabIdx, idx, true)                             
                                                        return {...prev, [`${cont}${index}`]: subbab.bobot[idx]}
                                                        })
                                                      }
                                                      />} 
                                                   />
                                              }
                                              </List>
                                              : ""
                                            )
                                          })}
                                          </RadioGroup>
                                      </FormControl>
                                      </React.Fragment>
                                      : ""
                                ))}
                                  <FormControl component="fieldset" >
                                  {sub.tipe === "Checkbox & Radio"
                                    ?
                                      <FormControlLabel 
                                      control={<Checkbox required={true} />} 
                                      label={<Markup content={"" + sub.sub_jawaban} />}
                                      key={sub.sub_jawaban}
                                      value={sub.sub_jawaban}
                                      onChange={(e) => {
                                        const val = sub.bobot_subjawaban
                                        let cont = quiz.id + "." 
                                        let id = `${cont}${index}`
                                        let jawaban = e.target.value;
                                        let indx = quiz.id;
                                        targetClick(e, id, val, jawaban, indx);
                                      }}/>
                                    : ""
                                  }
                                  <RadioGroup className={classes.root} >
                                  {sub.jawaban.map((pilih, idx) => {
                                    return(
                                      pilih !== "" && pilih !== "string"
                                      ?
                                        <List>
                                        {sub.tipe === "Checkbox"
                                          ?
                                          <React.Fragment>
                                            <FormControlLabel 
                                            control={<Checkbox required={true} 
                                            key={`${quiz.id}.${index}.${idx}`}
                                            value={pilih}
                                            defaultChecked={pilih.check}
                                            />}
                                            label={<Markup content={String.fromCharCode(65+idx)+ ". " + pilih} />} 
                                            onChange={(e) => {
                                              const val = sub.bobot[idx]
                                              let cont = quiz.id + "." 
                                              let id = `${cont}${index}.${idx}`
                                              let jawaban = e.target.value;
                                              let indx = quiz.id;
                                              targetClick(e, id, val, jawaban, indx);                          
                                            }}/>
                                            {sub.subbab_jawaban.map((subjwb, iddx) => { 
                                              return(
                                                subjwb !== null && subjwb.id === idx
                                                ? 
                                                <div className={classes.root}>
                                                  {subjwb.qst.map((itm, i) => {
                                                    return(
                                                      subjwb.tipe === "Pilih Banyak"
                                                        ?
                                                        <FormControlLabel 
                                                        control={<Checkbox required={true} />}
                                                        value={itm}
                                                        key={itm}
                                                        label={<Markup content={"" + itm} />} 
                                                        onChange={(e) => {
                                                          const val = subjwb.bobot[i]
                                                          let cont = quiz.id + "." 
                                                          let id = `${cont}${index}.${idx}.${i}`
                                                          let jawaban = e.target.value;
                                                          let indx = quiz.id;
                                                          targetClick(e, id, val, jawaban, indx);
                                                        }}/>
                                                        : 
                                                        <FormControlLabel 
                                                        control={<Radio required={true}
                                                        />}
                                                        value={itm}
                                                        key={itm}
                                                        label={<Markup content={"" + itm} />} 
                                                        onChange={(e) => {
                                                          const val = subjwb.bobot[i]
                                                          let cont = quiz.id + "." 
                                                          let id = `${cont}${index}.${idx}.${i}`
                                                          let jawaban = e.target.value;
                                                          let indx = quiz.id;
                                                          targetClick(e, id, val, jawaban, indx);
                                                        }}/>
                                                    )
                                                  })}
                                                </div>
                                                : ""
                                              )
                                            })}
                                          </React.Fragment>
                                          : [
                                            sub.tipe === "Checkbox & Radio"
                                            ?
                                              <React.Fragment>
                                                <FormControlLabel 
                                                  value={pilih}
                                                  key={pilih} 
                                                  label={<Markup content={String.fromCharCode( 65+idx)+ ". " + sub.jawaban[idx]} />}
                                                  control={<Radio required={true}/>} 
                                                  onChange={(e) => setBobot((prev) => {
                                                    let cont = quiz.id + "." 
                                                    let cpt = String.fromCharCode(65+idx)+ ".";
                                                    setJawab((jwb) => {
                                                      return {...jwb, [`jawaban${cont}${index+1}`]: cpt + e.currentTarget.value}
                                                    })                             
                                                    return {...prev, [`${cont}${index}`]: sub.bobot[idx]}
                                                  })}/>
                                              </React.Fragment>
                                            : <FormControlLabel 
                                              value={pilih}
                                              key={`${pilih}${quiz.id}${idx}`} 
                                              label={<Markup content={String.fromCharCode( 65+idx)+ ". " + pilih} />}
                                              control={<Radio required={true}/>} 
                                              onChange={(e) => setBobot((prev) => {
                                                let cont = quiz.id + "." 
                                                let cpt = String.fromCharCode(65+idx)+ ".";
                                                setJawab((jwb) => {
                                                  return {...jwb, [`jawaban${cont}${index+1}`]: cpt + e.currentTarget.value}
                                                })                             
                                                return {...prev, [`${cont}${index}`]: sub.bobot[idx]}
                                              })}/>
                                          ]
                                        }
                                        </List>
                                      : ""
                                    )
                                  })}
                                  </RadioGroup>
                                  </FormControl>
                              </div>
                            )
                          })}
                      </CardContent>
                      : null
                    );
                  })}
                  <Grid columns={2}>
                  <Upload {...formProps} className={classes.root}>
                    <Button
                      type="dashed"
                      color="primary"
                      variant="outlined"
                      className="ant-full-box"
                      endIcon={<ToTopOutlined />}
                    >
                      Upload File
                    </Button>
                  </Upload>
                  <br />
                    <Stack direction="row" spacing={2} display="flex" justifyContent="flex-end" 
                    alignItems="flex-end" gap='2'>
                      {qnIndex < 2 
                        ?
                          <Button
                          type='submit'
                          color="primary"
                          variant="contained"
                          disabled>
                          <CaretLeftOutlined /> Kembali 
                          </Button>
                        :
                          <LoadingButton
                          type='submit'
                          color="primary"
                          variant="contained"
                          loading={backLoading}
                          loadingPosition="start"
                          startIcon={<CaretLeftOutlined />}
                          style={{ backgroundColor: '#15406A', color: "white" }}
                          onClick={backSoal}>
                           Kembali 
                          </LoadingButton>
                      }
                    {qnIndex === 10
                          ?
                          <LoadingButton
                              type='submit'
                              color="primary"
                              variant="contained"
                              loading={loadingResult}
                              loadingPosition="end"
                              style={{ backgroundColor: '#15406A', color: "white" }}
                              endIcon={<SendOutlined />}
                              onClick={submitResult}
                              >
                              Selesaikan Kuisioner
                          </LoadingButton>
                          : 
                          <LoadingButton
                          type='submit'
                          color="primary"
                          variant="contained"
                          loading={loading}
                          loadingPosition="end"
                          style={{ backgroundColor: '#15406A', color: "white" }}
                          endIcon={<CaretRightOutlined />} 
                          onClick={nextSoal}>
                          Selanjutnya 
                          </LoadingButton>
                    }
                    </Stack>
                    </Grid>
                  </List>
                </CardContent>
              </Card>
              </Col>
              <Col xs={24} sm={24} md={10} lg={12} xl={6} className="mb-24">
              <Card sx={{ boxShadow: 3, margin: 2 }}>
              <CardContent>
              <Grid item xs={10} className={classes.typo}>
              {ar.map((itm, i) => {
                  return(
                    <Button variant="contained" className={classes.root} 
                    color="secondary" data-button-key={i+1}
                    id={i+1}
                    >{itm}</Button>
                  )
              })}
              </Grid>
              </CardContent>
              <Box justifyContent="center" alignItems="center" display='flex' sx={{ Width: '300px', borderRadius: '15px' }}>
              <CardActions>
              
              </CardActions>
              </Box>
              <br />
              </Card>
              </Col>
              </Row>
        )]}
        </div>
    )
  }
  
  