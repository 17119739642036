import 'antd/dist/antd.css';
import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Select,
  Upload,
  Row,
  Col,
  message,
  AutoComplete,
  Spin
} from 'antd';
import AppBar from '@mui/material/AppBar';
import logo from '../assets/images/naker.png';
import {Card, CardContent, Typography, List, Radio, RadioGroup, FormControl, Checkbox, Toolbar,Avatar,
  Box, Stack, Grid, ButtonGroup, LinearProgress, FormControlLabel, CardHeader, CardActionArea, CardActions} from "@mui/material";
import React from "react";
import axios from "axios";
import {useState, useEffect, useMemo} from "react";
import { endpoint } from "../utils/endpoint";
import { useHistory } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { debounce } from 'lodash';
  
export default function FormIsian() {
    const [dataProvinsi, setDataProvinsi] = useState([]);
    const [dataKota, setDataKota] = useState([]);
    const [ids, setIds] = useState(0);
    const [idP, setIdP] = useState("");
    const [namaPerusahaan, setnamaPerusahaan] = useState('');
    const [alamatPerusahaan, setAlamatperusahaan] = useState('');
    const [kabkota, setKabkota] = useState('');
    const [provinsi, setProvinsi] = useState('');
    const [stempel, setStempel] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const redirect = useHistory();

    const { perusahaan, kabKota, propinsi, alamat, formState: { errors } } = useForm();

    const useStyles = makeStyles((theme) => ({
      appBar: {
        top: "auto",
        bottom: 0
      },
      typo: {
        flexGrow: 1,
        textAlign: "center"
      }
    }));

    const classes = useStyles();

    const onChange = (e) => {
      setnamaPerusahaan(e.target.value);
    };

    const { Option } = Select;
    const { TextArea } = Input;

    const props = {
        headers: {
          authorization: 'authorization-text',
        },
        action : `${endpoint}/company/upload_stempel`,
        name: 'file'
    }
    
    const inputData = async (e) => {
      e.preventDefault();
      await axios.post(`${endpoint}/company/pendataan_perusahaan`, {"nama_perusahaan": namaPerusahaan, 
      "alamat": alamatPerusahaan, "kab_kota": kabkota, "provinsi": provinsi, "stempel": `media/stempel/${stempel}`})
      .then(res => {
        if(namaPerusahaan !== "" && alamatPerusahaan !== "" && propinsi !== "" && kabkota !== "") {
          message.success("Data berhasil di simpan");
          let Data = JSON.stringify(res.data);
          localStorage.setItem("data_peserta", Data);
          setInterval(function() {
            redirect.push('/kuisioner');
          }, 1000);
        } else {
            alert("Data harus dilengkapi");
            return false;
        }
      })
      .catch(err => {
        console.log(err);
      });
    };

    const getProvinsi = async () => {
        await axios.get(`${endpoint}/lokasi/`)
        .then((res) => {
          setDataProvinsi(res.data.data);
          console.log(res.data.data);
          console.log(res.data.data.filter(data => data.id === toString(ids)));
        }).catch(err => console.log(err));
    };
    
    useEffect(() => {
      getProvinsi();
    }, []);

    const getKota = async () => {
      await axios.get(`${endpoint}/lokasi/${ids}`)
      .then((res) => {
        setDataKota(res.data.data);
        console.log(res.data.data);
        let province = dataProvinsi.filter(data => data.id === idP);
        let getProv = province.map(data => data.name);
        setProvinsi(getProv.toString());
      }).catch(err => console.log(err));
    };

    useEffect(() => {
      getKota();
    }, [ids]);

    // const handleSearch = debounce(async (value) => {
    //   setnamaPerusahaan(value);
    //   if (value) {
    //     setLoading(true);
    //     try {
    //       const response = await axios.get(`${endpoint}/data-wlkp/search`, {
    //         params: { q: value,  page: 1, limit: 5 },
    //       });
    //       const searchResults = response.data.map(item => ({
    //         value: item.nama_perusahaan,
    //       }));
    //       setOptions(searchResults);
    //     } catch (error) {
    //       console.error("Error fetching search results:", error);
    //     } finally {
    //       setLoading(false);
    //     }
    //   } else {
    //     setOptions([]);  // Clear options if input is empty
    //   }
    // }, 300);

    const fetchSearchResults = useMemo(() =>
      debounce(async (query) => {
        setnamaPerusahaan(query);
        if (query) {
          setLoading(true);
          try {
            const response = await axios.get(`${endpoint}/data-wlkp/search`, {
              params: { q: query, page: 1, limit: 5 },
            });
  
            const searchResults = response.data.map(item => ({
              value: item.nama_perusahaan,
            }));
  
            setOptions(searchResults);
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoading(false);
          }
        } else {
          setOptions([]);
        }
      }, 100), []);  // 300ms debounce delay
  
    // Handle input changes
    const handleInputChange = (value) => {
      setInputValue(value);
      fetchSearchResults(value);  // Call debounced function
    };
    
    return (
      <>
      <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" sx={{ bgcolor: "#15406A", 'color': 'white' }}>
            <Toolbar>
            <Avatar alt="Remy Sharp" src={logo} sx={{ mr: 2 }} />
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                 Hubungan Industrial
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      <br />
      <Grid container wrap="nowrap" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={10}>
        <Card sx={{ boxShadow: 3, width: '100%', margin: 0, marginTop: '10px', marginBottom: '10px' }}>
        <CardHeader action={""} />
        <Typography variant="h5" className={classes.typo}>Form Perusahaan</Typography>
        <br />
          <CardContent>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              {...perusahaan}
            >
              <Form.Item label="Nama Perusahaan">
              <AutoComplete
                value={namaPerusahaan}
                options={options}
                placeholder="Nama Perusahaan"
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={handleInputChange}
              >
                <Input />
              </AutoComplete>
              </Form.Item>
              <Form.Item label="Provinsi">
              <Select
                placeholder="Provinsi"
                id='Prov'
                onChange={(e) => {
                  setIds(parseInt(e))
                  setIdP(e)
                }}
                {...propinsi}
                >
                {dataProvinsi.map((prov) => (
                  <Option value={prov.id}>{prov.name}</Option>
                ))}
              </Select>
              </Form.Item>
              <Form.Item label="Kabupaten / Kota">
              <Select
                placeholder="Kabupaten / Kota"
                onChange={e => setKabkota(e)}
                {...kabKota}
                >
                {dataKota.map((kota, i) => (
                  <Option key={i} value={kota.name}>{kota.name}</Option>
                ))}
              </Select>
              </Form.Item>
              <Form.Item label="Alamat Perusahaan">
                <TextArea rows={8.5} {...alamat} onChange={(e) => {setAlamatperusahaan(e.target.value)}} />
              </Form.Item>
              <Form.Item label="Upload Stempel" valuePropName="fileList">
                <Upload {...props}
                onChange={(response) => {
                  if (response.file.status !== 'uploading') {
                    console.log(response.file, response.fileList);
                  }
                  if (response.file.status === 'done') {
                    message.success(`${response.file.name} 
                                     Berhasil di upload`);
                    setStempel(response.file.name)
                  } else if (response.file.status === 'error') {
                    message.error(`${response.file.name} 
                                   file upload failed.`);
                  }
                }} 
                listType="picture-card">
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Pilih File</div>
                  </div>
                </Upload>
              </Form.Item>
              {errors.exampleRequired && <p>This field is required</p>}
              <Box minWidth='max-content' display="flex" justifyContent="flex-end" alignItems="flex-end" gap='2'>
                <Button
                  type='submit'
                  color="primary"
                  variant="contained"
                  endIcon={<SendOutlined />}
                  style={{ backgroundColor: '#15406A' }}
                  onClick={inputData}>
                  Mulai Kuisioner
                </Button>
              </Box>
            </Form>
          </CardContent>
        </Card>
        </Grid>
        </Grid>
        <br />
      </>
    );
  }
  
  