import { endpoint } from '../../utils/endpoint';
import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Select, Table, message, Space, Row, Col, Card, Radio, Popconfirm } from 'antd';
import axios from 'axios';
import { EditOutlined, DeleteOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const User = () => {
  const [dataUser, setDataUser] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // To store filtered data for search
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [provinsi, setProvinsi] = useState([]);
  const [kota, setKota] = useState([]);
  const [ids, setIds] = useState(0);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState(""); // State for the search query
  const token = localStorage.getItem("tokens");

  const fetchUsers = async (page) => {
    setLoading(true);
    try {
      const res = await axios.get(`${endpoint}/user/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDataUser(res.data);
      setFilteredData(res.data); // Initially, filteredData is the same as the full dataset
      setTotalPages(res.data.totalPages);
    } catch (err) {
      message.error('Error fetching users');
    }
    setLoading(false);
  };

  const fetchProvinsi = async () => {
    try {
      const res = await axios.get(`${endpoint}/lokasi/`);
      setProvinsi(res.data.data);
    } catch (err) {
      message.error('Error fetching provinsi');
    }
  };

  const fetchKota = async (provId) => {
    try {
      const res = await axios.get(`${endpoint}/lokasi/${provId}`);
      setKota(res.data.data);
    } catch (err) {
      message.error('Error fetching kota');
    }
  };

  useEffect(() => {
    fetchUsers(0);
    fetchProvinsi();
  }, []);

  const handleEditOpen = (user) => {
    setCurrentUser(user);
    form.setFieldsValue(user);
    fetchKota(user.regional); // Fetch cities for the user's region
    setEditOpen(true);
  };

  const handleEditSubmit = async (values) => {
    try {
      await axios.put(`${endpoint}/user/edit_user/${currentUser.id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success('User updated successfully');
      fetchUsers(0);
      setEditOpen(false);
    } catch (err) {
      message.error('Error updating user');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${endpoint}/user/delete_user/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDataUser((prev) => prev.filter((user) => user.id !== id));
      message.success('User deleted');
    } catch (err) {
      message.error('Error deleting user');
    }
  };

  const handleCreateSubmit = async (values) => {
    try {
      await axios.post(`${endpoint}/user/create_user`, values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success('User created successfully');
      fetchUsers(0);
      setOpen(false);
    } catch (err) {
      message.error('Error creating user');
    }
  };

  const handleRegionalChange = (provId, option) => {
    // Fetch kota when a province is selected
    fetchKota(provId);
    
    // Set both prov.name and prov.id in the form fields
    form.setFieldsValue({
      regional: option.children,  // Store prov.name
      regionalId: provId,         // Store prov.id for kota fetch
    });
  };

  // Search functionality to filter data
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = dataUser.filter((user) =>
      user.username.toLowerCase().includes(value)
    );
    setFilteredData(filtered); // Update the filteredData based on the search query
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Username"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => record.username.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Level',
      dataIndex: 'level',
    },
    {
      title: 'Actions',
      render: (_, user) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => handleEditOpen(user)}>
            Edit
          </Button>
          <Popconfirm
            title="Apakah anda ingin menghapus data ini?"
            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => handleDelete(user.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Tabel User"
            extra={
              <>
                <Input
                  placeholder="Cari berdasarkan username"
                  value={searchText}
                  onChange={handleSearch}
                  style={{ width: 300, marginRight: 10 }}
                  prefix={<SearchOutlined />}
                />
                <Button type="primary" onClick={() => setOpen(true)}>
                  Tambah User +
                </Button>
              </>
            }
          >
            <Table
              columns={columns}
              dataSource={filteredData} // Use filteredData for the table
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                onChange: fetchUsers,
              }}
              rowKey={(record) => record.id}
            />
          </Card>
        </Col>
      </Row>

      {/* Create User Modal */}
      <Modal
        title="Tambah User"
        visible={open}
        onCancel={() => setOpen(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} onFinish={handleCreateSubmit} layout="vertical">
          <Form.Item name="username" label="Username" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item name="regional" label="Regional" rules={[{ required: true }]}>
            <Select onChange={handleRegionalChange}>
              <Option value="PUSAT">PUSAT (Super Admin)</Option>
              {provinsi.map((prov) => (
                <Option key={prov.id} value={prov.id}>
                  {prov.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="kabupaten_kota" label="Kabupaten / Kota">
            <Select>
              {kota.map((city) => (
                <Option key={city.id} value={city.name}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="level" label="Level" rules={[{ required: true }]}>
            <Select>
              <Option value="Admin Pusat">Admin Pusat</Option>
              <Option value="Admin Provinsi">Admin Provinsi</Option>
              <Option value="Admin Kabupaten">Admin Kabupaten</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* Edit User Modal */}
      <Modal
        title="Edit User"
        visible={editOpen}
        onCancel={() => setEditOpen(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} onFinish={handleEditSubmit} layout="vertical">
          <Form.Item name="username" label="Username" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password">
            <Input.Password />
          </Form.Item>
          <Form.Item name="regional" label="Regional" rules={[{ required: true }]}>
            <Select onChange={handleRegionalChange}>
              <Option value="PUSAT">PUSAT (Super Admin)</Option>
              {provinsi.map((prov) => (
                <Option key={prov.id} value={prov.name}>
                  {prov.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="kabupaten_kota" label="Kabupaten / Kota">
            <Select>
              {kota.map((city) => (
                <Option key={city.id} value={city.name}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="level" label="Level" rules={[{ required: true }]}>
            <Select>
              <Option value="Admin Pusat">Admin Pusat</Option>
              <Option value="Admin Provinsi">Admin Provinsi</Option>
              <Option value="Admin Kabupaten">Admin Kabupaten</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default User;


