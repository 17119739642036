import 'antd/dist/antd.css';
import {
  Button,
  Form,
  Input,
  Select,
  notification,
  Alert, 
  Space,
  Upload,
  Typography, 
  Row,
  Col,
  Tooltip,
  message,
  Card,
  Divider,
  InputNumber,
  Table,
  Modal
} from 'antd';
import AppBar from '@mui/material/AppBar';
import logo from '../assets/images/naker.png';
import {CardContent, List, Radio, RadioGroup, FormControl, Checkbox, Toolbar,Avatar,
  Box, Stack, Grid, ButtonGroup, LinearProgress, FormControlLabel, CardHeader, CardActionArea, CardActions} from "@mui/material";
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import React from "react";
import axios from "axios";
import {useState, useEffect} from "react";
import { endpoint } from "../utils/endpoint";
import { useHistory } from 'react-router-dom';
// import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import {useParams} from 'react-router-dom';
  
export default function FormQuiz() {

    const redirect = useHistory();
    const getToken = localStorage.getItem("tokens");
    const [token, setToken] = useState(getToken || null);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [subSoal, setSubsoal] = useState("");
    const [subBabSoal, setSubBabsoal] = useState([]);
    const [subBabJawaban, setSubBabJawaban] = useState([]);
    const [subJawaban, setSubJawaban] = useState("");
    const [subBobot, setSubbobot] = useState(0);
    const [typeQuiz, settypeQuiz] = useState("");
    const [jawabanQ1, setJawabanQ1] = useState("");
    const [jawabanQ2, setJawabanQ2] = useState("");
    const [jawabanQ3, setJawabanQ3] = useState("");
    const [jawabanQ4, setJawabanQ4] = useState("");
    const [jawabanQ5, setJawabanQ5] = useState("");
    const [jawabanQ6, setJawabanQ6] = useState("");
    const [jawabanQ7, setJawabanQ7] = useState("");
    const [jawabanQ8, setJawabanQ8] = useState("");
    const [jawabanQ9, setJawabanQ9] = useState("");
    const [jawabanQ10, setJawabanQ10] = useState("");
    const [jawabanQ11, setJawabanQ11] = useState("");
    const [jawabanQ12, setJawabanQ12] = useState("");
    const [bobotQ1, setBobotQ1] = useState(0);
    const [bobotQ2, setBobotQ2] = useState(0);
    const [bobotQ3, setBobotQ3] = useState(0);
    const [bobotQ4, setBobotQ4] = useState(0);
    const [bobotQ5, setBobotQ5] = useState(0);
    const [bobotQ6, setBobotQ6] = useState(0);
    const [bobotQ7, setBobotQ7] = useState(0);
    const [bobotQ8, setBobotQ8] = useState(0);
    const [bobotQ9, setBobotQ9] = useState(0);
    const [bobotQ10, setBobotQ10] = useState(0);
    const [bobotQ11, setBobotQ11] = useState(0);
    const [bobotQ12, setBobotQ12] = useState(0);
    const [api, contextHolder] = notification.useNotification();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingSoalIndex, setEditingSoalIndex] = useState(null);
    const [currentSoalIndex, setCurrentSoalIndex] = useState(null);
    const [isSubPilihModalVisible, setIsSubPilihModalVisible] = useState(false);
    const [editingSubPilihIndex, setEditingSubPilihIndex] = useState(null);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [editingSubbabIndex, setEditingSubbabIndex] = useState(null);
    const [editingEntryIndex, setEditingEntryIndex] = useState(null);
    const [form] = Form.useForm();
    const [subPilihForm] = Form.useForm();

  // Show notification
  const notify = (type, message) => {
    notification[type]({
      message,
    });
  };

  // Add or Update Soal
  const handleOpenModal = (soal = null, index = null) => {
    setEditingSoalIndex(index);
    if (soal) {
      form.setFieldsValue({
        soal: soal.soal,
        pilih: soal.pilih.join('\n'),
        bobot: soal.bobot.join(','),
        tipe: soal.tipe,
      });
    } else {
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditingSoalIndex(null);
  };

  const handleSaveSoal = (values) => {
    const updatedPilih = values.pilih.split('\n');
    const updatedBobot = values.bobot.split(',').map((b) => parseInt(b, 10));
    const updatedSoal = {
      soal: values.soal,
      pilih: updatedPilih,
      bobot: updatedBobot,
      tipe: values.tipe,
      sub_pilih: editingSoalIndex !== null ? subBabSoal[editingSoalIndex].sub_pilih : [],
    };

    if (editingSoalIndex === null) {
      setSubBabsoal([...subBabSoal, updatedSoal]);
      notify('success', 'Update successfully!');
    } else {
      const updatedData = subBabSoal.map((item, index) =>
        index === editingSoalIndex ? updatedSoal : item
      );
      setSubBabsoal(updatedData);
      notify('success', 'Update successfully!');
    }
    handleCloseModal();
  };

  const handleDeleteSoal = (index) => {
    const updatedData = subBabSoal.filter((_, i) => i !== index);
    setSubBabsoal(updatedData);
  };

  const handleOpenSubPilihModal = (soalIndex, subIndex = null) => {
    setCurrentSoalIndex(soalIndex);
    setEditingSubPilihIndex(subIndex);

    if (subIndex !== null) {
      const subPilih = subBabSoal[soalIndex].sub_pilih[subIndex];
      subPilihForm.setFieldsValue({
        id: subPilih.id,
        pilih: subPilih.pilih.join('\n'),
        bobot: subPilih.bobot.join(','),
        tipe: subPilih.tipe,
      });
    } else {
      subPilihForm.resetFields();
    }
    setIsSubPilihModalVisible(true);
  };

  const handleSaveSubPilih = (values) => {
    const updatedPilih = values.pilih.split('\n');
    const updatedBobot = values.bobot.split(',').map((b) => parseInt(b, 10));

    const updatedSubPilih = {
      id: values.id,
      pilih: updatedPilih,
      bobot: updatedBobot,
      tipe: values.tipe,
    };

    const updatedData = [...subBabSoal];

    if (editingSubPilihIndex === null) {
      updatedData[currentSoalIndex].sub_pilih = [
        ...updatedData[currentSoalIndex].sub_pilih,
        updatedSubPilih,
      ];
    } else {
      updatedData[currentSoalIndex].sub_pilih = updatedData[currentSoalIndex].sub_pilih.map(
        (item, index) =>
          index === editingSubPilihIndex ? updatedSubPilih : item
      );
    }

    setSubBabsoal(updatedData);
    setIsSubPilihModalVisible(false);
    subPilihForm.resetFields();
    notify('success', 'Update successfully!');
  };

  const handleDeleteSubPilih = (soalIndex, subIndex) => {
    const updatedSoal = subBabSoal[soalIndex];
    updatedSoal.sub_pilih = updatedSoal.sub_pilih.filter((_, i) => i !== subIndex);
    const updatedData = subBabSoal.map((item, index) =>
      index === soalIndex ? updatedSoal : item
    );
    setSubBabsoal(updatedData);
    notify('success', 'Update successfully!');
  };

   const columns = [
    {
      title: 'Soal',
      dataIndex: 'soal',
      key: 'soal',
    },
    {
      title: 'Pilih',
      key: 'pilih',
      render: (text, record) => (record.pilih ? record.pilih.join(', ') : 'N/A'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record, index) => (
        <Space size="middle">
          <Button onClick={() => handleOpenModal(record, index)}>Edit</Button>
          <Button danger onClick={() => handleDeleteSoal(index)}>Delete</Button>
          <Button onClick={() => handleOpenSubPilihModal(index)}>Manage Sub Pilih</Button>
        </Space>
      ),
      responsive: ['xs', 'sm'],
    },
  ];

  // Render for sub_pilih inside expandable row
  const expandedRowRender = (record, index) => {
    const subColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        responsive: ['sm', 'md'],
      },
      {
        title: 'Sub Pilih',
        dataIndex: 'pilih',
        key: 'pilih',
        render: (text, subRecord) => (subRecord.pilih ? subRecord.pilih.join(', ') : 'N/A'),
      },
      {
        title: 'Bobot',
        dataIndex: 'bobot',
        key: 'bobot',
        render: (text, subRecord) => (subRecord.bobot ? subRecord.bobot.join(', ') : 'N/A'),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, subRecord, subIndex) => (
          <Space size="middle">
            <Button onClick={() => handleOpenSubPilihModal(index, subIndex)}>Edit</Button>
            <Button danger onClick={() => handleDeleteSubPilih(index, subIndex)}>Delete</Button>
          </Space>
        ),
        responsive: ['xs', 'sm'],
      },
    ];
    return <Table columns={subColumns} dataSource={record.sub_pilih} pagination={false} rowKey="id" />;
  };
    
    const useStyles = makeStyles((theme) => ({
      appBar: {
        top: "auto",
        bottom: 0
      },
      typo: {
        flexGrow: 1,
        textAlign: "center"
      }
    }));

    const classes = useStyles();

    const { Option } = Select;
    const openNotificationSucces = () => {
      api.success({
        message: `Update Berhasil`,
        description:
          ""
      });
    };

    const openNotificationError = (message) => {
      api.error({
        message: `Update Gagal`,
        description:
          message
      });
    };

    const helps = [
      <Tooltip title="Useful information">
        <Typography.Link href="#API">Need Help?</Typography.Link>
      </Tooltip>
    ]

  // Untuk Subbab Jawaban
  
  const handleOpenModal2 = (subbabIndex, entry = null, entryIndex = null) => {
    setEditingSubbabIndex(subbabIndex);
    setEditingEntryIndex(entryIndex);

    if (entry) {
      form.setFieldsValue({
        id: entry.id,
        qst: Array.isArray(entry.qst) ? entry.qst.join('\n') : '',
        bobot: Array.isArray(entry.bobot) ? entry.bobot.join(',') : '',
        tipe: entry.tipe,
      });
    } else {
      form.resetFields();
    }
    setIsModalVisible2(true);
  };

  const handleCloseModal2 = () => {
    setIsModalVisible2(false);
    form.resetFields();
    setEditingSubbabIndex(null);
    setEditingEntryIndex(null);
  };

  const handleSaveEntry2 = (values) => {
    const updatedQst = values.qst.split('\n');
    const updatedBobot = values.bobot.split(',').map((b) => parseInt(b, 10));
  
    const updatedEntry = {
      id: values.id,
      qst: updatedQst,
      bobot: updatedBobot,
      tipe: values.tipe,
    };
  
    // We make sure to shallow copy the entire data array and subbab array
    const updatedData = subBabJawaban.map((subbab, index) => 
      index === editingSubbabIndex
        ? [...subbab] // shallow copy of the specific subbab being modified
        : subbab
    );
  
    if (editingEntryIndex === null) {
      // Add new entry
      updatedData[editingSubbabIndex] = [...updatedData[editingSubbabIndex], updatedEntry];
    } else {
      // Update the existing entry immutably
      updatedData[editingSubbabIndex] = updatedData[editingSubbabIndex].map((item, index) =>
        index === editingEntryIndex ? updatedEntry : item
      );
    }
  
    setSubBabJawaban((prevData) => {
      const updatedData = prevData.map((subbab, index) => 
        index === editingSubbabIndex ? [...subbab] : subbab
      );
    
      if (editingEntryIndex === null) {
        updatedData[editingSubbabIndex] = [...updatedData[editingSubbabIndex], updatedEntry];
      } else {
        updatedData[editingSubbabIndex] = updatedData[editingSubbabIndex].map((item, index) =>
          index === editingEntryIndex ? updatedEntry : item
        );
      }
    
      return updatedData;
    });
     // Trigger state update with the new updated data array
    handleCloseModal2();
  };
  

  const handleDeleteEntry = (subbabIndex, entryIndex) => {
    const updatedData = subBabJawaban.map((subbab, index) =>
      index === subbabIndex
        ? subbab.filter((_, i) => i !== entryIndex)
        : subbab
    );
    setSubBabJawaban(updatedData);
  };

  const columns2 = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Qst',
      key: 'qst',
      render: (text, record) => (record.qst ? record.qst.join(', ') : 'N/A'),
    },
    {
      title: 'Bobot',
      key: 'bobot',
      render: (text, record) => (record.bobot ? record.bobot.join(', ') : 'N/A'),
    },
    {
      title: 'Tipe',
      dataIndex: 'tipe',
      key: 'tipe',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record, entryIndex, subbabIndex) => (
        <Space size="middle">
          <Button onClick={() => handleOpenModal2(subbabIndex, record, entryIndex)}>Edit</Button>
          <Button danger onClick={() => handleDeleteEntry(subbabIndex, entryIndex)}>Delete</Button>
        </Space>
      ),
      responsive: ['xs', 'sm', 'md'],
    },
  ];

  // Function to transform the array into the desired format
  const transformData = (dataArray) => {
    // Check if dataArray is empty, undefined, or null
    if (!dataArray || dataArray.length === 0) {
      return [];
    }

    // Return the array as a clean JSON structure without array index as keys
    return dataArray.map(item => ({ ...item }));
  };

  const transformedSubSoal = transformData(subBabSoal);
  const transformedSubq = transformData(subBabJawaban);

  console.log("Transformed Data: ", transformedSubSoal);
  console.log("Transformed Data: ", transformedSubq);
    
    const update = async () =>{
      await axios.put(`${endpoint}/kuisioner/edit_subquiz/${parseInt(params.id)}`, {
        "sub_soal": subSoal,
        "subbab_soal": transformedSubSoal,
        "sub_jawaban": subJawaban,
        "q1": jawabanQ1,
        "q2": jawabanQ2,
        "q3": jawabanQ3,
        "q4": jawabanQ4,
        "q5": jawabanQ5,
        "q6": jawabanQ6,
        "q7": jawabanQ7,
        "q8": jawabanQ8,
        "q9": jawabanQ9,
        "q10": jawabanQ10,
        "q11": jawabanQ11,
        "q12": jawabanQ12,
        "q13": "string",
        "sub_q": transformedSubq,
        "bobot_subjawaban": subBobot,
        "bobot1": bobotQ1,
        "bobot2": bobotQ2,
        "bobot3": bobotQ3,
        "bobot4": bobotQ4,
        "bobot5": bobotQ5,
        "bobot6": bobotQ6,
        "bobot7": bobotQ7,
        "bobot8": bobotQ8,
        "bobot9": bobotQ9,
        "bobot10": bobotQ10,
        "bobot11": bobotQ11,
        "bobot12": bobotQ12,
        "bobot13": 0,
        "tipe": typeQuiz
      } ). then(()=>
        openNotificationSucces()
      ).catch(err => err.message =="getPrefixCls is not a function"?console.log("Error data belum di ubah"):openNotificationError(err.message))
    }

    const getData = async () => {
      await axios.get(`${endpoint}/kuisioner/get_subquiz/${parseInt(params.id)}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      .then((res) => {
          setLoading(false);
          setSubsoal(res.data[0].sub_soal);
          setSubJawaban(res.data[0].sub_jawaban);
          setSubbobot(res.data[0].bobot_subjawaban);
          settypeQuiz(res.data[0].tipe);
          setSubBabsoal(res.data[0].subbab_soal)
          setSubBabJawaban(res.data[0].subbab_jawaban)
            //  console.log(res.data);
            //  console.log(res.data[0].jawaban[1]);
          setJawabanQ1(res.data[0].jawaban[0]);
          setJawabanQ2(res.data[0].jawaban[1])
          setJawabanQ3(res.data[0].jawaban[2]);;
          setJawabanQ4(res.data[0].jawaban[3]);
          setJawabanQ5(res.data[0].jawaban[4]);
          setJawabanQ6(res.data[0].jawaban[5]);
          setJawabanQ7(res.data[0].jawaban[6]);
          setJawabanQ8(res.data[0].jawaban[7]);
          setJawabanQ9(res.data[0].jawaban[8]);
          setJawabanQ10(res.data[0].jawaban[9]);
          setJawabanQ11(res.data[0].jawaban[10]);
          setJawabanQ12(res.data[0].jawaban[11]);

         setBobotQ1(res.data[0].bobot[0]);
         setBobotQ2(res.data[0].bobot[1])
         setBobotQ3(res.data[0].bobot[2]);;
         setBobotQ4(res.data[0].bobot[3]);
         setBobotQ5(res.data[0].bobot[4]);
         setBobotQ6(res.data[0].bobot[5]);
         setBobotQ7(res.data[0].bobot[6]);
         setBobotQ8(res.data[0].bobot[7]);
         setBobotQ9(res.data[0].bobot[8]);
         setBobotQ10(res.data[0].bobot[9]);
         setBobotQ11(res.data[0].bobot[10]);
         setBobotQ12(res.data[0].bobot[11]);
      }).catch(err => console.log(err));
    }

    useEffect(() => {
      getData();
    }, []);

   return (
        <>
        {contextHolder}
        <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
        <Card sx={{ boxShadow: 3, width: '100%', margin: 0, marginTop: '10px', marginBottom: '10px' }}>
          <CardHeader action={""} />
          <Typography variant="h5" className={classes.typo}>Form Sub Soal</Typography>
          <br />
            <CardContent>
              <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
              >
                <Form.Item
                  className="Form-label"
                  label="Sub Soal"
                >
                <Input
                  style={{ minWidth: 200, maxWidth: 500 }}
                  placeholder="Masukan Sub Soal"
                  value={subSoal}
                  onChange={(e) => setSubsoal(e.target.value)}
                />
                </Form.Item>
                <Form.Item
                  className="Form-label"
                  label="Sub Jawaban"
                >
                <Input
                  style={{ minWidth: 200, maxWidth: 500 }}
                  placeholder="Masukan Sub Jawaban"
                  value={subJawaban}
                  onChange={(e) => setSubJawaban(e.target.value)}
                />
                </Form.Item>
                <Form.Item 
                  className="Form-label"
                  label = "Bobot"
                >
                <Input
                  placeholder="Masukan Sub Bobot"  
                  style={{ minWidth: 200, maxWidth: 500 }}
                  value={subBobot}
                  onChange={(e) =>  setSubbobot(e.target.value)}
                />
                </Form.Item>
                  {subBabSoal.length !== 0 ? (
                  <Form.Item 
                    className="Form-label"
                    label = "Subbab Soal"
                  >
                    <div>
                    <Button type="primary" onClick={() => handleOpenModal()}>
                      Tambah Subbab Soal
                    </Button>
                        <Table
                        dataSource={subBabSoal}
                        columns={columns}
                        rowKey={(record, index) => index}
                        pagination={{ pageSize: 5 }}
                        expandable={{ expandedRowRender }}
                        scroll={{ x: true }}
                      />
                
                      {/* Modal for Add/Edit Soal */}
                      <Modal
                        title={editingSoalIndex === null ? "Add Soal" : "Edit Soal"}
                        visible={isModalVisible}
                        onCancel={handleCloseModal}
                        onOk={() => form.submit()}
                      >
                        <Form form={form} layout="vertical" onFinish={handleSaveSoal}>
                          <Form.Item name="soal" label="Soal" rules={[{ required: true, message: 'Please enter the soal' }]}>
                            <Input />
                          </Form.Item>
                          <Form.Item name="pilih" label="Pilihan (Setiap jawaban dipisah dengan shift + enter)" rules={[{ required: true }]}>
                            <Input.TextArea />
                          </Form.Item>
                          <Form.Item name="bobot" label="Bobot (Bobot dipisah dengan komma)" rules={[{ required: true }]}>
                            <Input />
                          </Form.Item>
                          <Form.Item name="tipe" label="Tipe" rules={[{ required: true }]}>
                          <Select
                            placeholder="Pilih Tipe Jawaban"
                            >
                            <Option value= "Pilih Banyak"> Pilih Banyak</Option>
                            <Option value= "Pilih Satu"> Pilih Salah Satu</Option>
                          </Select>
                          </Form.Item>
                        </Form>
                      </Modal>
                
                      {/* Modal for Add/Edit Sub Pilih */}
                      <Modal
                        title={editingSubPilihIndex === null ? "Add Sub Pilih" : "Edit Sub Pilih"}
                        visible={isSubPilihModalVisible}
                        onCancel={() => setIsSubPilihModalVisible(false)}
                        onOk={() => subPilihForm.submit()}
                      >
                        <Form form={subPilihForm} layout="vertical" onFinish={(values) => handleSaveSubPilih(values, editingSoalIndex)}>
                          <Form.Item name="id" label="ID" rules={[{ required: true, message: 'Please enter the ID' }]}>
                            <InputNumber />
                          </Form.Item>
                          <Form.Item name="pilih" label="Pilihan (Setiap jawaban dipisah dengan shift + enter)" rules={[{ required: true }]}>
                            <Input.TextArea />
                          </Form.Item>
                          <Form.Item name="bobot" label="Bobot (Bobot dipisah dengan komma)" rules={[{ required: true }]}>
                            <Input />
                          </Form.Item>
                          <Form.Item name="tipe" label="Tipe" rules={[{ required: true }]}>
                            <Select
                              placeholder="Pilih Tipe Jawaban"
                              >
                              <Option value= "Pilih Banyak"> Pilih Banyak</Option>
                              <Option value= "Pilih Satu"> Pilih Salah Satu</Option>
                            </Select>
                          </Form.Item>
                        </Form>
                      </Modal>
                    </div>
                  </Form.Item>
                  ) : (
                      <Form.Item 
                      className="Form-label"
                      label = "Subbab Soal"
                      >
                        <Input />
                      </Form.Item>
                  )}
                  {/* Subbab Soal List */}
                <Form.Item 
                  className="Form-label"
                  label = "Subbab Jawaban"
                >
                  {/* Sub Q */}
                  {subBabJawaban.map((subbab, subbabIndex) => (
                      <div key={subbabIndex}>
                        <Button
                          type="primary"
                          onClick={() => handleOpenModal2(subbabIndex)}
                          style={{ marginBottom: 16 }}
                        >
                          Tambah Subbab Jawaban {subbabIndex + 1}
                        </Button>
                        <Table
                          dataSource={subbab}
                          columns={columns2.map((column) => ({
                            ...column,
                            render: (text, record, entryIndex) =>
                              column.render
                                ? column.render(text, record, entryIndex, subbabIndex)
                                : text,
                          }))}
                          rowKey="id"
                          pagination={false}
                          scroll={{ x: true }}
                        />
                      </div>
                    ))}

                    {/* Modal for adding/editing Entry */}
                    <Modal
                      title={editingEntryIndex === null ? 'Add Entry' : 'Edit Entry'}
                      visible={isModalVisible2}
                      onCancel={handleCloseModal2}
                      onOk={() => form.submit()}
                    >
                      <Form form={form} onFinish={handleSaveEntry2}>
                        <Form.Item
                          name="id"
                          label="ID"
                          rules={[{ required: true, message: 'Please enter an ID' }]}
                        >
                          <InputNumber />
                        </Form.Item>
                        <Form.Item
                          name="qst"
                          label="Qst"
                          rules={[{ required: true, message: 'Please enter the Qst' }]}
                        >
                          <Input.TextArea rows={4} />
                        </Form.Item>
                        <Form.Item
                          name="bobot"
                          label="Bobot"
                          rules={[{ required: true, message: 'Please enter the Bobot' }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="tipe"
                          label="Tipe"
                          rules={[{ required: true, message: 'Please enter the Tipe' }]}
                        >
                          <Select
                            placeholder="Pilih Tipe Jawaban"
                            >
                            <Option value= "Pilih Banyak"> Pilih Banyak</Option>
                            <Option value= "Pilih Satu"> Pilih Salah Satu</Option>
                          </Select>
                        </Form.Item>
                      </Form>
                    </Modal>
                </Form.Item>
                <Form.Item label = "A"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ1} onChange={(e) =>  setJawabanQ1(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "B"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ2} onChange={(e) =>  setJawabanQ2(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "C"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ3} onChange={(e) =>  setJawabanQ3(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "D"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ4} onChange={(e) =>  setJawabanQ4(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "E"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ5} onChange={(e) =>  setJawabanQ5(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "F"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ6} onChange={(e) =>  setJawabanQ6(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "G"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ7} onChange={(e) =>  setJawabanQ7(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "H"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ8} onChange={(e) =>  setJawabanQ8(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "I"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ9} onChange={(e) =>  setJawabanQ9(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "J"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ10} onChange={(e) =>  setJawabanQ10(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "K"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ11} onChange={(e) =>  setJawabanQ11(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "L"  className="Form-label">
                <Input placeholder="Masukan Jawaban" style={{ minWidth: 200, maxWidth: 500 }} 
                 value ={jawabanQ12} onChange={(e) =>  setJawabanQ12(e.target.value)} 
                 />
                </Form.Item>
                <Form.Item label = "Bobot 1" className="Form-label">
                 <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ1} onChange={(e) =>  setBobotQ1(e.target.value)} />
                 </Form.Item> 
                 <Form.Item label = "Bobot 2"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ2} onChange={(e) =>  setBobotQ2(e.target.value)} />
                </Form.Item>
                <Form.Item label = "Bobot 3"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ3} onChange={(e) =>  setBobotQ3(e.target.value)} />
                </Form.Item>
                <Form.Item label = "Bobot 4"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ4} onChange={(e) =>  setBobotQ4(e.target.value)} />
                </Form.Item>
                <Form.Item label = "Bobot 5"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ5} onChange={(e) =>  setBobotQ5(e.target.value)} />
                </Form.Item>
                <Form.Item label = "Bobot 6"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ6} onChange={(e) =>  setBobotQ6(e.target.value)} /></Form.Item>
                <Form.Item label = "Bobot 7"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ7} onChange={(e) =>  setBobotQ7(e.target.value)} /></Form.Item>
                <Form.Item label = "Bobot 8"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ8} onChange={(e) =>  setBobotQ8(e.target.value)} /></Form.Item>
                <Form.Item label = "Bobot 9"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ9} onChange={(e) =>  setBobotQ9(e.target.value)} /></Form.Item>
                <Form.Item label = "Bobot 10"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ10} onChange={(e) =>  setBobotQ10(e.target.value)} /></Form.Item>
                <Form.Item label = "Bobot 11"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ11} onChange={(e) =>  setBobotQ11(e.target.value)} /> </Form.Item>
                <Form.Item label = "Bobot 12"  className="Form-label">
                <Input placeholder="Masukan Bobot" style={{ minWidth: 200, maxWidth: 500 }} value ={bobotQ12} onChange={(e) =>  setBobotQ12(e.target.value)} /> </Form.Item>
                <Form.Item label="Pilih Type Kuis">
                  <Select
                    placeholder="Pilih Tipe Kuis"
                    id={typeQuiz}
                    value={typeQuiz}
                    onChange={(e) => {
                      settypeQuiz(e)
                    }}
                    >
                    <Option value= "Checkbox"> Pilih Banyak</Option>
                    <Option value= "Radio"> Pilih Salah Satu</Option>
                    <Option value= "Checkbox & Radio"> Kombinasi</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => update() }
                    style={{marginLeft: 155}}
                  >
                   Update
                  </Button>
                </Form.Item>   
              </Form>
            </CardContent>
          </Card>
        </Col>
        </Row>
          <br />
        </>
      );
  };

