/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Input,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Tag,
    AutoComplete
  } from "antd";
  import moment from "moment";
  import { Link } from "react-router-dom";
  import React, { useEffect, useState} from "react";
  import { endpoint } from "../../utils/endpoint";
  import axios from "axios";
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable'
  import * as XLSX from 'xlsx';
  import {isEmpty} from 'lodash';
  import logo from '../../assets/images/Kementerian_Ketenagakerjaan.png';
  import { DownloadOutlined } from '@ant-design/icons';
  // Images
  
  const { Title } = Typography;  
  // table code start
  
  // project table start  
  function Perusahaan() {
    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    const [get_data, setGetData] = useState([]);
    const [get_dataProv, setGetDataProv] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalPagesProv, setTotalPagesProv] = useState(1);
    const [sortedInfo, setSortedInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const getToken = localStorage.getItem("tokens");
    const [token, setToken] = useState(getToken || null);
    let [filteredData] = useState();
    const [cari, setCari] = useState("");
    const [size, setSize] = useState('small');
    const [region, setRegion] = useState(localStorage.getItem("regional"));
    const [level, setLevel] = useState(localStorage.getItem("level"));
  
    const dataCompany = async () => {
        setLoading(true);
        await axios.get(`${endpoint}/company/data_perusahaan`)
        .then((res) => {
            setGetData(res.data);
            setTotalPages(res.data.length);
            setLoading(false);
        }).catch((err) => console.log(err));
    }
  
    useEffect(() => {
        dataCompany()
    }, []);

    const dataCompanyProv = async () => {
      setLoading(true);
      await axios.get(`${endpoint}/company/data_perusahaan/${region}`)
      .then((res) => {
          setGetDataProv(res.data);
          setTotalPagesProv(res.data.length);
          setLoading(false);
      }).catch((err) => console.log(err));
  }

  useEffect(() => {
      dataCompanyProv()
  }, []);

    const dataWithScore = get_data.map((item) => ({
       ...item
    }));

    const modifiedData = dataWithScore.map(({body, ...item}) => ({
        ...item,
        key: item.id,
        message: isEmpty(body) ? item.message: body,
    }));

    const columns = [
      {
        title: 'NO',
        dataIndex: 'No',
        width: '1%',
        key: "No"
      },
      {
        title: "NAMA PERUSAHAAN",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "ALAMAT",
        dataIndex: "function",
        key: "function",
        width: "20%",
      },
      {
        title: "PROVINSI",
        dataIndex: "provinsi",
        key: "provinsi",
      },
      {
        title: "KABUPATEN / KOTA",
        dataIndex: "kabkota",
        key: "kabkota",
      },
      {
        title: "STATUS",
        key: "status",
        dataIndex: "status",
      },
      {
        title: "WAKTU_UJIAN",
        key: "waktu_ujian",
        dataIndex: "waktu_ujian",
      },
      {
        title: "Download",
        key: "download",
        dataIndex: "download",
      },
    ];

    console.log(get_data);
  
    const data = get_data.map((d, index) => (
      {
        key: `${index+1}`,
        No: (
          <>
              <div className="avatar-info">
                <Title level={5}>{index+1}</Title>
              </div>
          </>
        ),
        name: (
          <>
              <div className="avatar-info">
                <Title level={5}>{d.nama_perusahaan}</Title>
              </div>
          </>
        ),
        function: (
          <>
            <div className="author-info">
              <Title level={5}>{d.alamat}</Title>
              <p>Organization</p>
            </div>
          </>
        ),

        provinsi: (
          <>
            <div className="author-info">
              <Title level={5}>{d.provinsi}</Title>
            </div>
          </>
        ),

        kabkota: (
          <>
            <div className="author-info">
              <Title level={5}>{d.kabkota}</Title>
            </div>
          </>
        ),
    
        status: (
          <>
          <p>Score: {d.score}</p>
          {d.score < 66
            ?
              <Tag color="error">LABEL MERAH</Tag>
            : [
              d.score > 65 && d.score < 80
              ?
              <Tag color="warning">LABEL KUNING</Tag>
              :
              <Tag color="success">LABEL HIJAU</Tag>
            ]
          }
          </>
        ),
        waktu_ujian: (
          <>
            <div className="ant-employed">
              <span>{moment(d.penilaian.waktu_selesai).format("D MMMM YYYY")}</span>
            </div>
          </>
        ),
        download: (
          <>
            <div className="ant-employed">
              <Button type="primary" shape="round" icon={<DownloadOutlined />} />
            </div>
          </>
        ),
      }
    ))

    const dataProv = get_dataProv.map((d, index) => (
      {
        key: `${index+1}`,
        No: (
          <>
              <div className="avatar-info">
                <Title level={5}>{index+1}</Title>
              </div>
          </>
        ),
        name: (
          <>
              <div className="avatar-info">
                <Title level={5}>{d.nama_perusahaan}</Title>
              </div>
          </>
        ),
        function: (
          <>
            <div className="author-info">
              <Title level={5}>{d.alamat}</Title>
              <p>Organization</p>
            </div>
          </>
        ),

        provinsi: (
          <>
            <div className="author-info">
              <Title level={5}>{d.provinsi}</Title>
            </div>
          </>
        ),

        kabkota: (
          <>
            <div className="author-info">
              <Title level={5}>{d.kabkota}</Title>
            </div>
          </>
        ),
    
        status: (
          <>
          <p>Score: {d.score}</p>
          {d.score < 66
            ?
              <Tag color="error">LABEL MERAH</Tag>
            : [
              d.score > 65 && d.score < 80
              ?
              <Tag color="warning">LABEL KUNING</Tag>
              :
              <Tag color="success">LABEL HIJAU</Tag>
            ]
          }
          </>
        ),
        waktu_ujian: (
          <>
            <div className="ant-employed">
              <span>{moment(d.penilaian.waktu_selesai).format("D MMMM YYYY")}</span>
            </div>
          </>
        ),
      }
    ))

    const downloadPDF = () => {
      const doc = new jsPDF();
      let body = []
      // generate auto table with body
      get_data.forEach((elem, i, arr) => {
        if(elem.score >= 79.99) {
          let status = "Hijau"
          body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
        } else if(elem.score < 80 && elem.score > 65) {
          let status = "Kuning"
          body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
        } else if(elem.score < 66) {
          let status = "Merah"
          body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
        }
      })
      var header = function (data) {
        doc.addImage(logo, 'PNG', data.settings.margin.left, 2, 45, 50);
        doc.setFontSize(11);
      };
      doc.setLineWidth(2);
      doc.autoTable({
        beforePageContent: header,
        head: [["No.","Nama Perusahaan","Alamat","Provinsi","Kabupaten / Kota", "Nilai", "Status Perusahaan"]],
        startY: 50,
        body: body,
        theme: 'grid',
      })
      // save the data to this file
      doc.save('data_perusahaan');
    }

    const downloadPDFProv = () => {
      const doc = new jsPDF();
      let body = []
      // generate auto table with body
      get_dataProv.forEach((elem, i, arr) => {
        if(elem.score >= 79.99) {
          let status = "Hijau"
          body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
        } else if(elem.score < 80 && elem.score > 65) {
          let status = "Kuning"
          body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
        } else if(elem.score < 66) {
          let status = "Merah"
          body.push([i + 1, elem.nama_perusahaan, elem.alamat, elem.provinsi, elem.kabkota, elem.score, status])
        }
      })
      var header = function (data) {
        doc.addImage(logo, 'PNG', data.settings.margin.left, 2, 45, 50);
        doc.setFontSize(11);
      };
      doc.setLineWidth(2);
      doc.autoTable({
        beforePageContent: header,
        head: [["No.","Nama Perusahaan","Alamat","Provinsi","Kabupaten / Kota", "Nilai", "Status Perusahaan"]],
        startY: 50,
        body: body,
        theme: 'grid',
      })
      // save the data to this file
      doc.save('data_perusahaan');
    }

    const downloadExcel = () => {
        const workSheet = XLSX.utils.json_to_sheet(get_data)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "Perusahaan")
        let buf = XLSX.write(workBook,{bookType: "xlsx", type: "buffer"})
        XLSX.write(workBook,{bookType: "xlsx", type: "binary"})
        XLSX.writeFile(workBook,"perusahaan.xlsx")
    }

    const downloadExcelProv = () => {
      const workSheet = XLSX.utils.json_to_sheet(get_dataProv)
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, workSheet, "Perusahaan")
      let buf = XLSX.write(workBook,{bookType: "xlsx", type: "buffer"})
      XLSX.write(workBook,{bookType: "xlsx", type: "binary"})
      XLSX.writeFile(workBook,"perusahaan.xlsx")
  }

    const handleChange = (...sorted) => {
        const {order,field} = sorted[2];
        setSortedInfo({columnKey: field, order})
    }

    const handleInputChange = (e) => {
      setCari(e.target.value)
      if(e.target.value === "") {
          dataCompany();
      } 
    };

    const globalSearch = () => {
        filteredData = modifiedData.filter((value) => {
            return(
                value.nama_perusahaan.toLowerCase().includes(cari.toLowerCase()) ||
                value.alamat.toLowerCase().includes(cari.toLowerCase()) ||
                value.provinsi.toLowerCase().includes(cari.toLowerCase()) ||
                value.kabkota.toLowerCase().includes(cari.toLowerCase())
            );
        });
        setGetData(filteredData)
    };
  
    return (
      <>
        <div className="tabled">
        {region === "All" ? (
            <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Tabel Perusahaan"
                extra={
                  <>
                    <Input style={{ width: 280 }} placeholder="Cari..." 
                      type="text"
                      onChange={handleInputChange}
                      value={cari}
                      /> 
                    <Radio.Group onChange={onChange} defaultValue="a">
                      <Button onClick={globalSearch} type="primary">Cari</Button>
                      <Button onClick={downloadPDF}>PDF</Button>
                      <Button onClick={downloadExcel} success>Excel</Button>
                    </Radio.Group>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={filteredData && filteredData.length ? filteredData : data}
                    loading={loading}
                    onChange={handleChange}
                    pagination={{
                        pageSize: 10,
                        total: totalPages,
                        onChange: (page) => {
                          dataCompany(page);
                        },
                    }}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Tabel Perusahaan"
              extra={
                <>
                  <Input style={{ width: 280 }} placeholder="Cari..." 
                    type="text"
                    onChange={handleInputChange}
                    value={cari}
                    /> 
                  <Radio.Group onChange={onChange} defaultValue="a">
                    <Button onClick={globalSearch} type="primary">Cari</Button>
                    <Button onClick={downloadPDFProv}>PDF</Button>
                    <Button onClick={downloadExcelProv} success>Excel</Button>
                  </Radio.Group>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={filteredData && filteredData.length ? filteredData : dataProv}
                  loading={loading}
                  onChange={handleChange}
                  pagination={{
                      pageSize: 10,
                      total: totalPagesProv,
                      onChange: (page) => {
                        dataCompany(page);
                      },
                  }}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
        )}
        </div>
      </>
    );
  }
  
  export default Perusahaan;
  