import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Col, Card, Table, Spin, message, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import { endpoint } from '../../utils/endpoint';

const token = localStorage.getItem("tokens");

const EditUserProv = () => {
  const [dataUser, setDataUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentUser, setCurrentUser] = useState(null); // Selected user for editing
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const regional = localStorage.getItem("regional");

  console.log(regional);

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  // Fetch Users from the server
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${endpoint}/user/${regional}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if response is in the correct format
      if (res.data && Array.isArray(res.data)) {
        setDataUser(res.data); // Assuming the response has a users array
        setTotalPages(res.data.totalPages || 1); // Handle pagination if totalPages exist
      } else {
        message.error('Unexpected response format');
      }
    } catch (err) {
      message.error('Error fetching users: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Open modal for editing user
  const openEditModal = (user) => {
    setCurrentUser(user); // Set the selected user
    setIsModalOpen(true);
  };

  // Close the modal after submission or cancel
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentUser(null); // Clear selected user
  };

  // Submit updated user data
  const handleEditSubmit = async (values) => {
    setLoading(true);
    try {
      await axios.put(`${endpoint}/user/edit-user-prov/${currentUser.id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success('User berhasil di update!');
      fetchUsers(currentPage); // Refresh user list after update
      closeModal(); // Close modal
    } catch (err) {
      message.error('Error updating user: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Form submission handlers
  const onFinish = (values) => {
    if (currentUser) {
      handleEditSubmit(values);
    } else {
      message.error('No user selected for editing');
    }
  };

  const onFinishFailed = (errorInfo) => {
    message.error('Failed: ' + errorInfo);
  };

  // Table columns for displaying users
  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: 'Regional',
      dataIndex: 'regional',
      key: 'regional',
    },
    {
      title: 'Aksi',
      key: 'aksi',
      render: (_, record) => (
        <Button icon={<EditOutlined />} onClick={() => openEditModal(record)}>
          Edit
        </Button>
      ),
    },
  ];

  // Handle table pagination
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <Row gutter={[24, 0]}>
      <Col xs={24} sm={24} className="mb-24">
        <Card bordered={false} className="criclebox h-full">
          {loading ? (
            <Spin />
          ) : (
            <Table
              columns={columns}
              dataSource={dataUser}
              rowKey="id"
              pagination={{
                current: currentPage,
                total: totalPages * 10, // Assuming 10 items per page
              }}
              onChange={handleTableChange}
            />
          )}
        </Card>

        {/* Modal for Editing User */}
        <Modal
          title="Edit User"
          visible={isModalOpen}
          onCancel={closeModal}
          footer={null} // Custom footer with form submission
        >
          {currentUser && (
            <Form
              name="edit_user"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{
                username: currentUser.username,
                password: '', // Empty password initially for security reasons
                level: currentUser.level, // Set default level from currentUser
                regional: regional, // Set default regional from currentUser
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input the username!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input the password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="Level"
                name="level"
                rules={[
                  {
                    required: true,
                    message: 'Please input the level!',
                  },
                ]}
              >
                <Input readOnly/>
              </Form.Item>

              <Form.Item
                label="Provinsi"
                name="regional"
              >
                <Input readOnly/>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Update
                </Button>
              </Form.Item>
            </Form>
          )}
        </Modal>
      </Col>
    </Row>
  );
};

export default EditUserProv;
